import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomInput from "../../../Core/Components/CustomInput";
import { LOCATION_TYPE } from "../../Location/Data";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import FilterItem from "../../../Core/Components/FilterItem";
import { paymentStatusFilterOptions } from "./data";
import { PAYMENT_RECEIVER_TYPE } from "../data";

const PaymentHeader = ({
  search,
  setSearch,
  filters,
  setFilters,
  setCurrentPage,
  selectedView,
  setSelectedView,
}) => {
  const { allLocations } = useSelector((state) => state.locations);

  // get all the hubs
  const hubFilters = allLocations
    .filter((item) => item.type === LOCATION_TYPE.HUB_STATION)
    .map((location) => ({
      id: +location.id,
      value: location.name,
    }));

  const [focus, setFocus] = useState(false);

  const onSelect = (key, value) => {
    // whenever applying the filter, the page should be defaulted back to first page
    setCurrentPage(1);

    if (filters[key].includes(value)) {
      const newFilters = filters[key].filter((item) => item !== value);
      setFilters({ ...filters, [key]: newFilters });
    } else {
      setFilters({ ...filters, [key]: [...filters[key], value] });
    }
  };

  return (
    <div className="bg-background-white">
      <div className="px-6 py-2.5 flex items-center justify-between border-t-[0.5px] border-light-grey">
        <div className="flex items-center gap-x-3">
          <CustomInput
            intent="search"
            prefix={
              <img src="/assets/icons/search.svg" alt="" className="mr-2" />
            }
            value={search}
            focus={focus}
            border={focus ? "success" : "none"}
            placeholder={focus ? null : "Search Users"}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            suffix={
              search.length > 0 ? (
                <CloseIcon
                  className="w-4 h-4 cursor-pointer stroke-dark-grey-text"
                  onClick={() => {
                    setFocus(false);
                    setSearch("");
                  }}
                />
              ) : null
            }
          />

          <div className="flex items-center">
            <FilterItem
              label="Hub Station"
              identifier="hub"
              contentData={hubFilters}
              onSelect={onSelect}
              selectedValues={filters.hub}
              className="max-h-[200px] overflow-y-scroll"
              enableSearch
            />

            {/* <FilterItem
              label="User Type"
              identifier="userType"
              customValue
              className="max-h-max"
              contentData={userTypeFilterOptions}
              onSelect={onSelect}
              selectedValues={filters.userType}
            /> */}

            <FilterItem
              label="Payment Status"
              identifier="paymentStatus"
              customValue
              className="max-h-max"
              contentData={paymentStatusFilterOptions}
              onSelect={onSelect}
              selectedValues={filters.paymentStatus}
            />
          </div>
        </div>

        <div
          className="w-[158px] h-[38px] flex items-center rounded-md p-0.5"
          style={{
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          <button
            type="button"
            className={`flex-1 py-2 px-4 rounded-md transform transition-colors duration-200 ${
              selectedView === PAYMENT_RECEIVER_TYPE.FARMER
                ? "bg-primary text-white"
                : "bg-transparent text-dark-gray"
            }`}
            onClick={() => setSelectedView(PAYMENT_RECEIVER_TYPE.FARMER)}
          >
            <p className="font-poppins font-medium text-xs leading-[18px]">
              Farmers
            </p>
          </button>

          <button
            type="button"
            className={`flex-1 py-2 px-4 rounded-md transform transition-colors duration-150 ${
              selectedView === PAYMENT_RECEIVER_TYPE.DRIVER
                ? "bg-primary text-white"
                : "bg-transparent text-dark-gray"
            }`}
            onClick={() => setSelectedView(PAYMENT_RECEIVER_TYPE.DRIVER)}
          >
            <p className="font-poppins font-medium text-xs leading-[18px]">
              Drivers
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentHeader;
