import React from "react";
import getUserType from "../getUserType";
import { getCollectionPaymentStatus } from "../getPaymentStatus";
import TripsDue, { sortByTripsDue } from "../PaymentList/TripsDue";
import TotalAmountDue, {
  sortByTotalAmountDue,
} from "../PaymentList/TotalAmountDue";
import PaymentStatus from "./PaymentStatus";

export const collectionColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Name
      </div>
    ),
    dataIndex: "firstName",
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-black capitalize">
        {text ? `${text} ${record.lastName}` : ""}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Main Hub
      </div>
    ),
    dataIndex: "hubName",
    sorter: (a, b) => a.hubName.localeCompare(b.hubName),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        User Type
      </div>
    ),
    dataIndex: "userType",
    render: (text) => <div className="max-w-max">{getUserType(text)}</div>,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        No. of Trips Due
      </div>
    ),
    dataIndex: "tripsDue",
    sorter: (a, b) => sortByTripsDue(a, b),
    render: (text, record) => <TripsDue payment={record} />,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Total Amount Due
      </div>
    ),
    dataIndex: "amountDue",
    sorter: (a, b) => sortByTotalAmountDue(a, b),
    render: (text, record) => <TotalAmountDue payment={record} />,
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Payment Status
      </div>
    ),
    dataIndex: "paymentStatus",
    render: (text, record) => <PaymentStatus payment={record} />,
  },
];

export const CollectionKPIData = (kpidata) => {
  return [
    {
      title: "Total Amount Collected",
      count: kpidata?.total ? parseFloat(kpidata.total).toFixed(2) : 0,
    },

    {
      title: "Amount Due From Partners",
      count: kpidata?.offtakers ? parseFloat(kpidata.offtakers).toFixed(2) : 0,
    },
  ];
};

export const collectionPaymentStatusOptions = [
  {
    id: 1,
    value: getCollectionPaymentStatus(1),
  },
  {
    id: 2,
    value: getCollectionPaymentStatus(2),
  },
  {
    id: 3,
    value: getCollectionPaymentStatus(3),
  },
];
