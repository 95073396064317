/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import axios from "axios";
import CustomLayout from "../../Core/Layout/CustomLayout";
import CustomMapMarker from "../../Core/Components/CustomMapMarker";
import TrikeMapMarkerDetails, {
  MapDetailDrawer,
  OtherMapMarkerDetails,
} from "../../Core/Components/CustomMapMarkerDetails";
import { getAllTrikesV3, getChecklist } from "../../Redux/APIs/trikesAPI";
import { getAllBatteriesV3 } from "../../Redux/APIs/batteriesAPI";
import {
  getAllHubs,
  getAllLocationsV3,
  getGlobalLocations,
} from "../../Redux/APIs/locationsAPI";
import getColorAndIcon from "../../Core/utils/getColorAndIcon";
import {
  getAllSystemUsers,
  getMetaData,
  getUserByEmail,
} from "../../Redux/APIs/systemUsersAPI";
import { updateLoggedInUser } from "../../Redux/Slices/loggedInUser.slice";
import { getHomeKpi } from "../../Redux/APIs/homeAPI";
import { updateTrikes } from "../../Redux/Slices/trikes.slice";
import MobiliseModalV3 from "../../Core/Components/Modal/MobiliseModalV3";
import { TRIKE_STATUS } from "../Trikes/Data";
import TrikeListings from "./TrikeListings";
import MapHeader from "./MapHeader";
import { INITIAL_ZOOM_LEVEL, MAX_BOUNDS } from "./Data/HomeData";
import NoHubStationModal from "./NoHubStationModal";
import { updateHome } from "../../Redux/Slices/home.slice";
import { getAllTillers, getTillerChecklist } from "../../Redux/APIs/tillersAPI";
import { getAllDrivers } from "../../Redux/APIs/usersAPI";

const Home = () => {
  const dispatch = useDispatch();

  const { locationDetails, userDetails } = useSelector(
    (state) => state.loggedInUser,
  );
  const { mobiliseModalV3 } = useSelector((state) => state.trikes);

  const { noHubStationModal } = useSelector((state) => state.home);

  const [locationsLoading, setLocationsLoading] = useState(false);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);

  const mapRef = useRef(null);
  const myRef = useRef([]);

  const initialZoomLevel = 11;

  const renderCustomIcon = (type, trike = {}) => {
    const { colors, icon, topIcon } = getColorAndIcon(type);
    // eslint-disable-next-line new-cap
    return new L.divIcon({
      html: ReactDOMServer.renderToString(
        <CustomMapMarker
          position="map"
          icon={icon}
          topIcon={
            trike?.status == TRIKE_STATUS.ON_TRIP ||
            trike?.status == TRIKE_STATUS.OUT_OF_GEOFENCE
              ? topIcon
              : null
          }
          colors={colors}
        />,
      ),
    });
  };

  const getAssetLatLngs = () => {
    return [-0.6803741, 34.7569482];
  };

  const getWeatherInformation = useCallback(async () => {
    try {
      if (lat && long) {
        const result = await axios.get(
          "https://api.openweathermap.org/data/2.5/weather",
          {
            params: {
              lat,
              lon: long,
              appid: process.env.REACT_APP_WEATHER_API_KEY,
              units: "metric",
            },
          },
        );

        const temperature = result?.data?.main?.temp;
        const city = result?.data?.name;
        const countryCode = result?.data?.sys?.country;

        dispatch(
          updateLoggedInUser({
            key: "locationDetails",
            value: { ...locationDetails, temperature, city, countryCode },
          }),
        );
      }
    } catch (err) {
      console.log(err, "Error in fetching the weather");
    }
  }, [dispatch, lat, long]);

  const assignRefsForMarkers = (ref, item) => {
    if (item.type === "5") {
      myRef[item.id] = ref;
    } else {
      myRef[item.name] = ref;
    }
  };

  useEffect(() => {
    getWeatherInformation(lat, long);
  }, [getWeatherInformation]);

  useEffect(() => {
    const getCoordinates = () => {
      navigator.geolocation.getCurrentPosition((position) => {
        setLat(position?.coords?.latitude);
        setLong(position?.coords?.longitude);
      });
    };

    getCoordinates();

    const fetchData = async () => {
      dispatch(getMetaData());
      dispatch(
        updateLoggedInUser({
          key: "settingsTab",
          value: "general",
        }),
      );
      dispatch(getAllTrikesV3());
      dispatch(getAllBatteriesV3());
      dispatch(getAllLocationsV3());
      dispatch(getGlobalLocations());
      dispatch(getAllHubs());
      dispatch(getHomeKpi());
      dispatch(getChecklist());
      dispatch(getAllSystemUsers());
      dispatch(getUserByEmail({ email: userDetails.email }));
      dispatch(getAllTillers());
      dispatch(getTillerChecklist());
      dispatch(getAllDrivers());
    };

    fetchData();
  }, [dispatch]);

  console.log(userDetails, "check logged in user");

  return (
    <CustomLayout pageName="Home">
      <div className="w-full h-full">
        <Row className="h-full">
          <Col span={6}>
            <TrikeListings mapRef={mapRef} />
          </Col>

          <Col span={18}>
            <div className="relative w-full h-full">
              <MapHeader
                setFilteredLocations={setFilteredLocations}
                setLocationsLoading={setLocationsLoading}
              />

              <MapContainer
                center={getAssetLatLngs()}
                zoom={INITIAL_ZOOM_LEVEL}
                maxBounds={MAX_BOUNDS}
                zoomControl={false}
                className="z-10 w-full h-full"
                ref={mapRef}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  minZoom={3}
                  maxZoom={17}
                />
                {/* mapping all the trikes, farms and other locations on the map */}
                {!locationsLoading &&
                  filteredLocations?.map((item, index) => {
                    return (
                      <div key={index + 1}>
                        {/* mapping the items based on the type, 5 represents trike */}
                        {item.type === "5" ? (
                          <div>
                            {/* if the location for the trike is not available, we do not show it on the map */}
                            {item?.locationLat == 0 &&
                            item?.locationLong == 0 ? (
                              <div />
                            ) : (
                              <Marker
                                ref={(el) => assignRefsForMarkers(el, item)}
                                position={[
                                  item?.locationLat,
                                  item?.locationLong,
                                ]}
                                icon={renderCustomIcon(item?.type, item)}
                              >
                                {/* trike popup with relevant trike details */}
                                <Popup>
                                  <TrikeMapMarkerDetails
                                    assetId={item.registrationNo}
                                    trikeDetail={item}
                                  />
                                </Popup>
                              </Marker>
                            )}
                          </div>
                        ) : (
                          // marker for the items other than trike
                          <Marker
                            ref={(el) => assignRefsForMarkers(el, item)}
                            position={[item?.lat, item?.long]}
                            icon={renderCustomIcon(item?.type)}
                          >
                            <Popup>
                              <OtherMapMarkerDetails item={item} />
                            </Popup>
                          </Marker>
                        )}
                      </div>
                    );
                  })}
              </MapContainer>

              <div className="z-20">
                <MapDetailDrawer
                  mapRef={mapRef}
                  initialZoomLevel={initialZoomLevel}
                  center={getAssetLatLngs()}
                  getColorAndIcon={getColorAndIcon}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <MobiliseModalV3
        visible={mobiliseModalV3?.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "mobiliseModalV3",
              value: {
                visibe: false,
                trike: {},
              },
            }),
          )
        }
      />

      <NoHubStationModal
        visible={noHubStationModal}
        onCancel={() =>
          dispatch(updateHome({ key: "noHubStationModal", value: false }))
        }
      />
    </CustomLayout>
  );
};

export default Home;
