import React from "react";

export const sortByTotalAmount = (a, b) => {
  const totalAmount = (collection) => {
    const total = collection.produceDetails.reduce((acc, current) => {
      acc += parseFloat(current.amount);
      return acc;
    }, 0);

    return total > 0 ? total : 0;
  };

  const first = totalAmount(a);
  const second = totalAmount(b);

  return second - first;
};

const TotalAmountColumn = ({ collection }) => {
  const totalAmount = () => {
    const total = collection.produceDetails.reduce((acc, current) => {
      acc += parseFloat(current.amount);
      return acc;
    }, 0);

    return total > 0 ? `${parseFloat(total).toFixed(2)} KSH` : "--";
  };

  return (
    <div className="text-xs font-medium font-poppins leading-4.5">
      {totalAmount()}
    </div>
  );
};

export default TotalAmountColumn;
