import { createAsyncThunk } from "@reduxjs/toolkit";
import { client as axios } from "../../Utils/axiosClient";

export const getPaymentKPI = createAsyncThunk(
  "revenue/getPaymentKPI",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/revenue/payment-kpi`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getFarmerPaymentList = createAsyncThunk(
  "revenue/getFarmerPaymentList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/revenue/farmer-payment-list`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getDriverPaymentList = createAsyncThunk(
  "revenue/getDriverPaymentList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/revenue/driver-payment-list`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPaymentDetails = createAsyncThunk(
  "revenue/getPaymentDetails",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/revenue/payment-details`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getCollectionKPI = createAsyncThunk(
  "revenue/getCollectionKPI",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/revenue/collection-kpi`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getCollectionList = createAsyncThunk(
  "revenue/getCollectionList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/revenue/collection-list`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getCollectionDetails = createAsyncThunk(
  "revenue/getCollectionDetails",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/revenue/collection-details`,
        { params },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updatePaymentStatus = createAsyncThunk(
  "revenue/updatePaymentStatus",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/revenue/update-payment-status`,
        payload,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
