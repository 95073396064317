import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PAYMENT_RECEIVER_TYPE } from "../data";

export const CommodityColumn = ({ payment }) => {
  const [produces, setProduces] = useState([]);

  const getProduces = useCallback(() => {
    const uniqueProduces = [];

    const local = payment.produceDetails.filter(
      (item) => item.weightCollected > 0,
    );

    local.forEach((item) => {
      if (!uniqueProduces.find((p) => p.produceId === item.produceId)) {
        uniqueProduces.push({
          produceId: item.produceId,
          produceName: item.produceName,
        });
      }
    });

    setProduces(uniqueProduces);
  }, [payment]);

  useEffect(() => {
    getProduces();
  }, [getProduces]);

  return (
    <div>
      {produces.map((item, index) => (
        <p
          key={item.produceId}
          className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize"
          style={{
            marginTop: index !== 0 && "8px",
            textTransform: "capitalize",
          }}
        >
          {item?.produceName}
        </p>
      ))}
    </div>
  );
};

export const WeightCollectedColumn = ({ payment }) => {
  const [produces, setProduces] = useState([]);

  const getProduces = useCallback(() => {
    const local = payment?.produceDetails?.filter(
      (item) => item.weightCollected > 0,
    );

    const unique = local.reduce((acc, current) => {
      const found = acc.find((item) => item.produceId === current.produceId);

      if (found) {
        found.weightCollected += current.weightCollected;
      } else {
        acc.push({
          produceId: current?.produceId,
          produceName: current?.produceName,
          weightCollected: parseFloat(current.weightCollected),
          unit: current?.unit?.slice(4),
        });
      }

      return acc;
    }, []);

    setProduces(unique);
  }, [payment]);

  const getWeight = (item) => {
    return item?.weightCollected
      ? `${parseFloat(item.weightCollected).toFixed(2)} ${item.unit}`
      : 0;
  };

  useEffect(() => {
    getProduces();
  }, [getProduces]);

  return (
    <div>
      {produces.map((item, index) => (
        <p
          key={item.produceId}
          className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize"
          style={{
            marginTop: index !== 0 && "8px",
            textTransform: "capitalize",
          }}
        >
          {/* {item?.weightCollected} {item?.unit} */}
          {getWeight(item)}
        </p>
      ))}
    </div>
  );
};

export const RateColumn = ({ payment }) => {
  const { selectedPayment } = useSelector((state) => state.revenue);

  const [rates, setRates] = useState([]);

  const getRates = useCallback(() => {
    const uniqueRates = [];

    const local = payment.produceDetails.filter(
      (item) => item.weightCollected > 0,
    );

    local.forEach((item) => {
      if (!uniqueRates.find((p) => p.produceId === item.produceId)) {
        uniqueRates.push({
          produceId: item.produceId,
          produceName: item.produceName,
          unit: item.unit,
          rate:
            selectedPayment.userType === PAYMENT_RECEIVER_TYPE.DRIVER
              ? item?.commissionRate
              : item?.paymentRate,
        });
      }
    });

    setRates(uniqueRates);
  }, [payment, selectedPayment]);

  useEffect(() => {
    getRates();
  }, [getRates]);

  return (
    <div>
      {rates.map((item, index) => (
        <p
          key={item.produceId}
          className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize"
          style={{
            marginTop: index !== 0 && "8px",
            textTransform: "capitalize",
          }}
        >
          {item?.rate}KSH/{item?.unit}
        </p>
      ))}
    </div>
  );
};

export const AmountCommodityColumn = ({ payment }) => {
  const [produces, setProduces] = useState([]);

  const getProduces = useCallback(() => {
    const local = payment.produceDetails.filter(
      (item) => item.weightCollected > 0,
    );

    const unique = local.reduce((acc, current) => {
      const found = acc.find((item) => item.produceId === current.produceId);

      if (found) {
        found.amount += current.amount;
      } else {
        acc.push({
          produceId: current.produceId,
          produceName: current.produceName,
          amount: parseFloat(current.amount),
          unit: current?.unit?.slice(4),
        });
      }

      return acc;
    }, []);

    setProduces(unique);
  }, [payment]);

  const getAmount = (item) => {
    return item?.amount ? `${parseFloat(item?.amount).toFixed(2)} KSH` : 0;
  };

  useEffect(() => {
    getProduces();
  }, [getProduces]);

  return (
    <div>
      {produces.map((item, index) => (
        <p
          key={item.produceId}
          className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize"
          style={{
            marginTop: index !== 0 && "8px",
            textTransform: "capitalize",
          }}
        >
          {/* {item?.amount} KSH */}
          {getAmount(item)}
        </p>
      ))}
    </div>
  );
};

export const TotalAmountColumn = ({ payment }) => {
  const totalAmount = () => {
    const total = payment.produceDetails.reduce((acc, current) => {
      acc += parseFloat(current.amount);
      return acc;
    }, 0);

    return total > 0 ? `${parseFloat(total).toFixed(2)} KSH` : "--";
  };

  return (
    <div className="text-xs font-medium font-poppins leading-4.5">
      {totalAmount()}
    </div>
  );
};

export const sortByTotalAmount = (a, b) => {
  const totalAmount = (payment) => {
    const total = payment.produceDetails.reduce((acc, current) => {
      acc += parseFloat(current.amount);
      return acc;
    }, 0);

    return total > 0 ? total : 0;
  };

  const first = totalAmount(a);
  const second = totalAmount(b);

  return second - first;
};
