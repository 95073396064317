/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomKPI from "../../../Core/Components/CustomKpi";
import { FinanceHoverIcon } from "../../../Common/Svgs/FinanceIcon";
import { paymentColumns, PaymentKPIData } from "./data";
import PaymentHeader from "./PaymentHeader";
import CustomTable from "../../../Core/Components/CustomTable";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { updateRevenue } from "../../../Redux/Slices/revenue.slice";
import {
  getDriverPaymentList,
  getFarmerPaymentList,
  getPaymentKPI,
} from "../../../Redux/APIs/revenueAPI";
import { PAYMENT_RECEIVER_TYPE } from "../data";
import useDebounce from "../../../Core/hooks/useDebounce";

const LOCAL_DEFAULT_LIMIT = 10;

const PaymentList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { paymentKPI, farmerPaymentList, driverPaymentList } = useSelector(
    (state) => state.revenue,
  );

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    hub: [],
    userType: [],
    paymentStatus: [],
  });
  const [selectedView, setSelectedView] = useState(
    PAYMENT_RECEIVER_TYPE.FARMER,
  );

  const debounceSearch = useDebounce(search, 500);

  const handleRowClick = (record) => {
    let receiverId = 0;

    if (record.userType === PAYMENT_RECEIVER_TYPE.DRIVER) {
      receiverId = record.driverId;
    } else {
      receiverId = record.farmerId;
    }

    dispatch(
      updateRevenue({
        key: "selectedPayment",
        value: { ...record, receiverId },
      }),
    );

    navigate(`/revenue/payment-details/${receiverId}`);
  };

  // fetch the payment list on every page change
  useEffect(() => {
    const filterObj = {
      offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      limit: (currentPage || 1) * LOCAL_DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;
    if (filters.hub.length) filterObj.hubIds = filters.hub;
    if (filters.paymentStatus.length)
      filterObj.paymentStatus = filters.paymentStatus;

    if (selectedView === PAYMENT_RECEIVER_TYPE.FARMER) {
      dispatch(getFarmerPaymentList(filterObj));
    } else {
      dispatch(getDriverPaymentList(filterObj));
    }
  }, [dispatch, currentPage, filters, debounceSearch, selectedView]);

  // get payment kpi
  useEffect(() => {
    dispatch(getPaymentKPI());
  }, []);

  return (
    <div>
      <div className="w-full flex items-center gap-x-6 p-6">
        {PaymentKPIData(paymentKPI).map((item) => (
          <CustomKPI
            key={item.title}
            title={item.title}
            count={item.count}
            image={<FinanceHoverIcon size={16} className="text-kpi-icon" />}
            unit="KSH"
          />
        ))}
      </div>

      <div>
        <PaymentHeader
          search={search}
          setSearch={setSearch}
          filters={filters}
          setFilters={setFilters}
          setCurrentPage={setCurrentPage}
          selectedView={selectedView}
          setSelectedView={setSelectedView}
        />

        <div
          className={`h-[calc(100vh-72px-71px-162px-69px-40px)] ${
            selectedView === PAYMENT_RECEIVER_TYPE.FARMER
              ? farmerPaymentList.result.length && "overflow-y-scroll"
              : driverPaymentList.result.length && "overflow-y-scroll"
          }`}
        >
          <CustomTable
            // scrollX={0}
            // scrollY="518px"
            disableScroll
            columns={paymentColumns}
            data={
              selectedView === PAYMENT_RECEIVER_TYPE.FARMER
                ? farmerPaymentList.result
                : driverPaymentList.result
            }
            onRowClick={handleRowClick}
            emptyState={
              <FinanceHoverIcon size={16} className="text-dark-grey-text" />
            }
            rowClassName="group cursor-pointer"
            pagination={{
              pageSize: LOCAL_DEFAULT_LIMIT,
              total:
                selectedView === PAYMENT_RECEIVER_TYPE.FARMER
                  ? farmerPaymentList.totalRecords
                  : driverPaymentList.totalRecords,
              showSizeChanger: false,
              onChange: (page) => {
                setCurrentPage(page);
              },
              current: currentPage,
              itemRender: (current, type, originalElement) => {
                const lastPage =
                  selectedView === PAYMENT_RECEIVER_TYPE.FARMER
                    ? farmerPaymentList.totalRecords
                    : driverPaymentList.totalRecords / current;

                if (type === "prev" && current === 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "prev" && current > 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next" && lastPage <= 10) {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{ pointerEvents: "none" }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next") {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                return originalElement;
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentList;
